export const useAfterLoginUrl = () => {
    const localePath = useLocalePath();

    return {
        setUrl: (url: string) => {
            localStorage.setItem("afterLoginUrl", url);
        },
        getAndDeleteUrl: () => {
            const defaultUrl = localePath("/portal/card");
            const afterLoginUrl = localStorage.getItem("afterLoginUrl");
            localStorage.removeItem("afterLoginUrl");
            return afterLoginUrl || defaultUrl;
        },
    };
};
